import { Injectable } from '@angular/core';
import { NavigationExtras, Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {

    constructor(private router: Router) { }

    navigateTo(command: any[], extras?: NavigationExtras) {
        this.router.navigate(command, extras);
    }

    navigateToNewTab(command: any[], extras?: NavigationExtras) {
        const url = this.router.createUrlTree(command, extras);
        window.open(url.toString(), '_blank')
    }

    goToMainPage(extras?: any): void {
        this.navigateTo(['/view'], extras);
    }

    goToLoginPage(extras?: any): void {
        this.navigateTo(['/login'], extras);
    }

}